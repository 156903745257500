import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Sidebar from '../../components/universities/sidebar';
import { getJobList } from '../../utils/api';
import { formatTime } from '../../utils/util';
import * as styles from './Job.module.scss';

class Job extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobItem: {},
      jobBaseIntro: [{
        id: 0,
        title: '招聘类型',
        key: 'CategoryDescription',
      }, {
        id: 1,
        title: '工作性质',
        key: 'KindDescription',
      }, {
        id: 2,
        title: '薪资范围',
        key: 'Salary',
      },
      {
        id: 3,
        title: '招聘人数',
        key: 'HeadCount',
      },
      {
        id: 4,
        title: '发布时间',
        key: 'StartDate',
        notShowMobile: true,
      },
      {
        id: 5,
        title: '截止时间',
        key: 'EndDate',
        notShowMobile: true,
      }, {
        id: 6,
        title: '工作地点',
        key: 'WorkingPlace',
      },
      ],
      salaryMap: {
        1: '1000以下',
        2: '1000~2000',
        3: '2001~4000',
        4: '4001~6000',
        5: '6001~8000',
        6: '8001~10000',
        7: '10001~15000',
        8: '15001~25000',
        9: '25000~30000',
        10: ' 面议',
        11: ' 30000~50000',
        12: ' 50000~70000',
        13: ' 70000~100000',
        14: ' 100000以上',
      },
    };
  }

  componentDidMount() {
    // console.log('recruitmentDetail', this.props);
    this.getJobItem().then((res) => {
      this.setState({
        jobItem: res,
      });
    });
  }

  // 申请职位
  applyJob() {
    const Id = '350383738';
    window.open(`https://anker.zhiye.com/Portal/Resume/ResumeImport?jId=${Id}&idType=0&r=%2fzpdetail%2f${Id}%3fk%3d%25E4%25BA%25A7%25E5%2593%2581`);
  }

  async getJobItem() {
    return new Promise(async (resolve, reject) => {
      const Id = '350383738';
      const params = {
        pageNum: 1,
        pageSize: 100,
        jobadCategory: 0,
      };
      return await getJobList(params).then(async (res) => {
        const jobItem = this.findJobItem(res.data, Id);
        if (jobItem) {
          resolve(jobItem);
        } else {
          // todo 显示loading
          let pageNum = 1;
          const pageSize = 100;
          const { jobadCategory } = params;
          while (true) {
            let resData;
            await getJobList({
              pageNum,
              pageSize,
              jobadCategory,
            }).then((res) => {
              resData = res.data;
              const jobItem = this.findJobItem(resData, Id);
              if (jobItem) {
                resolve(jobItem);
              }
              pageNum++;
            });
            if (resData.length < pageSize || jobItem) {
              this.setState({
                dataLoadingEnd: true,
              });
              break;
            }
          }
          // resolve(jobItem)
        }
      }).catch((err) => {
        console.log(err);
      });
    });
  }

  findJobItem(data, Id) {
    const jobItem = data.find((item) => item.Id == Id);
    return jobItem;
  }

  render() {
    const temp = {
      banner: 'https://cdn.shopify.com/s/files/1/0586/4941/1793/files/banner_5298c819-dbf9-4a3f-bd21-6de6b5f0f496.jpg?v=1646902912',
      mobileBanner: 'https://cdn.shopify.com/s/files/1/0586/4941/1793/files/375_128banner.jpg?v=1646902912',
      section2: [
        {
          text: '项目简介',
          url: '/project/introduction',
        },
        {
          text: '项目亮点',
          url: '/project/highlights',
        },
        {
          text: '职位信息',
          url: '/project/job',
        },
        {
          text: '创业者案例',
          url: '/project/entrepreneurcase',
        },
      ],
      seo: {
        title: '安克创新招聘.安克创新校园招聘.加入安克创新',
        keywords: ['安克创新招聘', '长沙上市公司招聘', '跨境电商公司招聘', '长沙互联网公司招聘', 'Anker招聘', '智能硬件出海公司招聘'],
        description: '作为标杆出海企业，安克创新致力于在全球市场塑造中国消费电子品牌，通过不断创新，将富有科技魅力的领先产品带向全球消费者，弘扬中国智造之美。安克创新现成功打造了3个年营收过15亿的出海品牌，拥有全球100多个国家与地区的7500万用户。',
      },
    };
    const {
      data, location = {},
    } = this.props;
    const copywriting = data.contentfulCopywriting;
    const { pathname } = location;
    const NoDataPlaceholder = () => {
      if (this.state.dataLoadingEnd) {
        return (
          <div>无数据</div>
        );
      }
      return null;
    };
    const {
      jobItem = {}, jobBaseIntro, seo, salaryMap,
    } = this.state;
    jobItem.HeadCount = jobItem.HeadCount > 0 ? jobItem.HeadCount : '若干';
    jobItem.StartDate = formatTime(jobItem.StartDate, 'YYYY-MM-DD');
    jobItem.EndDate = formatTime(jobItem.EndDate, 'YYYY-MM-DD');
    jobItem.Salary = salaryMap[jobItem.Salary];

    return (
      <div className={styles.home}>
        <img className="pageBanner" src={temp.banner} />
        <img className="mobileBanner" src={temp.mobileBanner} />
        <Layout {...this.props} hideSearch={true} copywriting={copywriting}>
          <SEO title={temp.seo.title} keywords={temp.seo.keywords} description={temp.seo.description} />
          <div className={'projectIntroduction'}>
            <Sidebar pathname={pathname} temp={temp.section2}></Sidebar>
            <div className="jobIntroContainer">
              <div className={styles.RecruitmentDetail}>
                <div className={styles.contentBox}>
                  <span className={styles.jobTitle}>{jobItem.JobTitle}</span>

                  {
                    jobItem.Id
                      ? <div>
                        <div className={styles.jobBaseIntroPc}>
                          {
                            jobBaseIntro.map((item, index) => (
                              <div key={item.id} className={styles.contentItem}>
                                <span className={styles.contentItemLeft}>{item.title}</span>
                                <span>{jobItem[item.key]}</span>
                              </div>
                            ))
                          }
                        </div>

                        <div className={styles.jobBaseIntroMobile}>
                          {
                            jobBaseIntro.map((item, index) => {
                              if (item.notShowMobile) {
                                return null;
                              }
                              return (
                                <div key={item.id} className={styles.contentItem}>
                                  <span>{jobItem[item.key]}</span>
                                </div>
                              );
                            })
                          }
                        </div>

                        <div>
                          <div className={styles.detailedInstructions}>
                            <span className={styles.detailedInstructionsTitle}>工作职责</span>
                            <pre className={styles.preClass} dangerouslySetInnerHTML={{ __html: jobItem.Duty }}>
                            </pre>
                          </div>

                          <div className={styles.detailedInstructions}>
                            <span className={styles.detailedInstructionsTitle}>任职资格</span>
                            <pre className={styles.preClass} dangerouslySetInnerHTML={{ __html: jobItem.Requirements }}>
                            </pre>
                          </div>
                        </div>

                        <div className={styles.button} onClick={this.applyJob.bind(this)}>申请职位</div>

                      </div>
                      : <NoDataPlaceholder></NoDataPlaceholder>
                  }

                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default Job;

export const query = graphql`
query jobQuery($node_locale: String) {
  contentfulCopywriting(node_locale: {eq: $node_locale}) {
    node_locale
    common {
      hot_btn {
        link
        show_paths
        hot_btn_text
      }
    }
    footer {
      brands {
        link
        image
      }
      followus {
        title
        list {
          type
          image
          imageact
          clickFunction
          link
        }
      }
      copyright
      logo
    }
    header {
      links {
        menuId
        name
        url
      }
    }
    search {
      search_btn_text
      placeholder
      options {
        category
        description
        url
      }
    }
  }
} 
`;
