import React, { useState, useEffect, useCallback } from 'react';
import { throttle } from '../utils/helper';
import Header from './header';
import Search from './common/search';

let lastScrollPosition = 0;

const MixHeader = (props) => {
  const { copywriting, headerProps, hideSearch, location, pageFixedSearch } = props;
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('up'); // 新增状态来跟踪滚动方向
  const [maskVisible, setMaskVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const setMobile = useCallback(() => setIsMobile(window?.innerWidth < 768), []);

  useEffect(() => {
    setMobile();
    window.addEventListener("resize", setMobile);
    return () => window.removeEventListener("resize", setMobile);
  }, [setMobile]);
  const orderScroll = () => {
    const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
    setCurrentScrollPosition(currentScroll);
    if (currentScroll > lastScrollPosition) {
      setScrollDirection('down');
    } else if (currentScroll < lastScrollPosition) {
      setScrollDirection('up');
    }
    lastScrollPosition = currentScroll;
  };
  useEffect(() => {
    lastScrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
    const throttledOrderScroll = throttle(orderScroll, 200);
    window.addEventListener('scroll', throttledOrderScroll);
    return () => {
      window.removeEventListener('scroll', throttledOrderScroll);
    };
  }, []);
  let originalOverflow = '';
  const inputFocus = () => {
    originalOverflow = document.documentElement.style.overflow;
    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';
    setMaskVisible(true);
  };

  const inputBlur = () => {
    document.documentElement.style.overflow = originalOverflow;
    document.body.style.overflow = originalOverflow;
    setMaskVisible(false);
  };
  return <div className={`fixed top-0 left-0 w-full z-50 transform transition-all duration-300 ${currentScrollPosition > 0 ? !isMobile ? 'bg-white translate-y-0' : 'bg-transparent' : 'bg-transparent -translate-y'}`}>
    {/* {(scrollDirection === 'up' || currentScrollPosition <= 80) && <Header
      copywriting={copywriting.header}
      id="header"
      isHome={isHome}
      hideSearch={hideSearch}
      location={location} />} */}
    {(!isMobile || (isMobile && currentScrollPosition === 0)) && <div className={`transform transition-all duration-300 ${(scrollDirection === 'up' || currentScrollPosition <= 80)
      ? 'opacity-100 translate-y-0 mt-0'
      : 'opacity-0 -translate-y cheeredscale-ycannotfindex-y-[-10px] -mt-[80px]'
      }`}>
      <Header
        copywriting={copywriting.header}
        id="header"
        // isHome={isHome}
        {...headerProps}
        hideSearch={hideSearch}
        location={location} />
    </div>}
    {/* {!isMobile || (currentScrollPosition <= 80) && <div className={`transform transition-all duration-300 ${(scrollDirection === 'up' || currentScrollPosition <= 80)
      ? 'opacity-100 translate-y-0 mt-[0]'
      : 'opacity-0 -translate-y cheeredscale-ycannotfindex-y-[-10px] -mt-[80px]'
      }`}> */}
    {/* {isMobile === false || (isMobile === true && currentScrollPosition <= 80) && <div className={`transform transition-all duration-300 ${isMobile === false && (scrollDirection === 'up' || currentScrollPosition <= 80)
      ? 'opacity-100 translate-y-0 mt-[0]'
      : 'opacity-0 -translate-y cheeredscale-ycannotfindex-y-[-10px] -mt-[80px]'
      }`}> */}
    <Search
      scrollDirection={scrollDirection}
      currentScrollPosition={currentScrollPosition}
      location={location}
      copywriting={copywriting.search}
      hideSearch={hideSearch}
      pageFixedSearch={pageFixedSearch}
      handleFocus={inputFocus}
      handleBlur={inputBlur}
    />
    {/* </div>} */}
    {maskVisible && <div className={`fixed top-0 left-0 w-full bg-black bg-opacity-20 z-40 ${(scrollDirection === 'up' || currentScrollPosition <= 80) ? 'top-[152px]' : 'top-[72px]'} ${(scrollDirection === 'up' || currentScrollPosition <= 80) ? 'h-[calc(100vh-152px)]' : 'h-[calc(100vh-80px)]'}`} />}
  </div>
};

export default MixHeader;